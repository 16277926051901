import styled from '@emotion/styled';
import utilities from '../../styles/modules/utilities.module.scss';
import colors from '../../styles/modules/colors.module.scss';
import rhythm from '../../utils/rhythm';

const Styles = styled.nav({
  // padding: `0 ${rhythm(1)}`,
  // backgroundColor: `rgba(255,255,255,0.23)`,

  '.navbar-brand': {
    fontFamily: utilities.fontFamilySerif,
    fontSize: rhythm(4 / 5),
    textTransform: 'none',
    color: colors.gray700,
    transform: 'translateY(-3px)',
  },

  a: {
    fontSize: rhythm(3 / 5),
    textTransform: 'uppercase',
  },
});

export default Styles;
