import React from 'react';
import { Link } from 'gatsby';
// import { useStaticQuery, graphql, Link } from 'gatsby';

import Styles from './footer.styles';
import { Container } from 'react-bootstrap';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export default function Footer() {
  return (
    <Styles>
      <Container className={`py-5`}>
        <ul className={`list-unstyled mt-4`}>
          <li>
            <Link to="/for-advisors/" activeClassName={`active`}>
              For Advisors
            </Link>
          </li>
          <li>
            <Link to="/why-it-works/" activeClassName={`active`}>
              Why It Works
            </Link>
          </li>
          <li>
            <AnchorLink to="/pricing#advisorPricing">Pricing</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/for-advisors#faq">
              Frequently Asked Questions
            </AnchorLink>
          </li>
          <li>
            <Link to="/terms-and-conditions/" activeClassName={`active`}>
              Terms &amp; Conditions
            </Link>
          </li>
        </ul>
        <p className={`d-block my-4 text-muted`}>
          <small>
            © {new Date().getFullYear()}
            {` `}
            <a
              href={`https://www.cosgroveandco.ca/`}
              className={`cosgrove`}
              target={`_blank`}
            >
              Cosgrove&amp;Co.
            </a>
          </small>
        </p>
      </Container>
    </Styles>
  );
}
