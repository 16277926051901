import styled from '@emotion/styled';
// import responsive from '../../utils/responsive';
// import rhythm from '../../utils/rhythm';
// import colors from '../../styles/modules/colors.module.scss';
import utilities from '../../styles/modules/utilities.module.scss';

const Styles = styled.footer({
  '.cosgrove': {
    fontFamily: utilities.fontFamilySerif,
  },
});

export default Styles;
