import React from 'react';
import { Link } from 'gatsby';

import Styles from './navbar.styles';
import { Container } from 'react-bootstrap';
import { ReactComponent as BHSLogoBlack } from '../../images/logos/bhs-logo.svg';

const Navbar = () => {
  return (
    <Styles className={`navbar navbar-expand-lg fixed-top`}>
      <Container>
        <Link to="/" className={`navbar-brand mb-2`} activeClassName={`active`}>
          <BHSLogoBlack alt="Board HealthScore Logo" />
        </Link>

        <ul className="navbar-nav navbar-collapse d-none">
          <li>
            <Link to="/for-advisors/" activeClassName={`active`}>
              For Advisors
            </Link>
          </li>
          <li>
            <Link to="/why-it-works/" activeClassName={`active`}>
              Why It Works
            </Link>
          </li>
          <li>
            <Link to="/pricing/" activeClassName={`active`}>
              Pricing
            </Link>
          </li>
          <li>
            <a href={`https://app.boardhealthscore.com`}>Log In</a>
          </li>
        </ul>

        <a
          href={`https://app.boardhealthscore.com/`}
          target={`_blank`}
          className={`btn btn-primary btn-sm d-none d-lg-block`}
        >
          {/*Try It Now*/}
          Get Started
        </a>
      </Container>
    </Styles>
  );
};

export default Navbar;
