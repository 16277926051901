import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Styles from './mobileMenu.styles';

// Based on: https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const MobileMenu = ({ open }) => {
  return (
    <Styles open={open}>
      <ul className="list-unstyled text-center">
        <li>
          <Link to="/for-advisors/" activeClassName={`active`}>
            For Advisors
          </Link>
        </li>
        <li>
          <Link to="/why-it-works/" activeClassName={`active`}>
            Why It Works
          </Link>
        </li>
        <li>
          <Link to="/pricing/" activeClassName={`active`}>
            Pricing
          </Link>
        </li>
        <li>
          <a href={`https://app.boardhealthscore.com`}>Log In</a>
        </li>
        <a href={`https://app.boardhealthscore.com/`} target={`_blank`} className={`btn btn-primary btn-sm`}>
          {/*Try It Now*/}
          Get Started
        </a>
      </ul>
    </Styles>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MobileMenu;
